import React from 'react'
import { Link, graphql } from 'gatsby'

import { mapEdgesToNodes } from '../lib/helpers'
import { BlogPostPreviewGrid } from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Breadcrumb from '../components/layout/breadcrumb'
import * as styles from './archive.module.css'

import { responsiveTitle1 } from '../components/typography.module.css'

export const query = graphql`
  query ArchivePageQuery {
    activities: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { name: { regex: "/activities/" } } }
    ) {
      totalCount
    }
    lectures: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "19" } } } }
    ) {
      totalCount
    }
    activitiesTable: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "20" } } } }
    ) {
      totalCount
    }
    visits: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "21" } } } }
    ) {
      totalCount
    }
    schoolVisits: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "22" } } } }
    ) {
      totalCount
    }
    qasInConferences: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "23" } } } }
    ) {
      totalCount
    }
    astronomyTrips: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "24" } } } }
    ) {
      totalCount
    }
    observation: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "25" } } } }
    ) {
      totalCount
    }
    courses: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "26" } } } }
    ) {
      totalCount
    }
    management: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "27" } } } }
    ) {
      totalCount
    }
    qasInMedia: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { tags: { elemMatch: { name: { eq: "28" } } } }
    ) {
      totalCount
    }
    astronomy_events: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { name: { regex: "/astronomy_events/" } } }
    ) {
      totalCount
    }
    news: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { name: { regex: "/news/" } } }
    ) {
      totalCount
    }
    occasions: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { name: { regex: "/occasions/" } } }
    ) {
      totalCount
    }
    crescents: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { name: { regex: "/crescents/" } } }
    ) {
      totalCount
    }
    articles: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { name: { regex: "/articles/" } } }
    ) {
      totalCount
    }
  }
`

const ArchivePage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const category = data.activities.totalCount

  let navItemStack = [{ name: 'الأرشيف', slug: 'archive' }]
  return (
    <Layout>
      <SEO title="صفحة الأرشيف" />
      <Container>
        <Breadcrumb currentNavItems={navItemStack} />
        <h1 className={responsiveTitle1}>الأرشيف</h1>

        <section className={styles.main}>
          <p>
            <Link to={'/anshth-w-faalyat'}>الأنشطة</Link>
            <span> ({data.activities.totalCount})</span>
          </p>
          <ul>
            <li>
              {' '}
              <Link to={`/tags/${19}`}>المحاضرات والندوات</Link>
              <span> ({data.lectures.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${20}`}>جدول أنشطة الجمعية</Link>
              <span> ({data.activitiesTable.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${21}`}>زيارات المختصين والمهتمين للجمعية</Link>
              <span> ({data.visits.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${22}`}>زيارات الطلاب والجمهور للجمعية</Link>
              <span> ({data.schoolVisits.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${23}`}>مشاركات الجمعية الخارجية</Link>
              <span> ({data.qasInConferences.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${24}`}>الرحلات الفلكية</Link>
              <span> ({data.astronomyTrips.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${25}`}>الرصد</Link>
              <span> ({data.observation.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${26}`}>الدورات</Link>
              <span> ({data.courses.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${27}`}>الإدارة</Link>
              <span> ({data.management.totalCount})</span>
            </li>
            <li>
              {' '}
              <Link to={`/tags/${28}`}>الجمعية في الإعلام</Link>
              <span> ({data.qasInMedia.totalCount})</span>
            </li>
          </ul>
          <p>
            <Link to={'/news'}>جديد الفلك</Link>
            <span> ({data.news.totalCount})</span>
          </p>
          <p>
            <Link to={'/crescents'}>أهلة الشهور</Link>
            <span> ({data.crescents.totalCount})</span>
          </p>
          <p>
            <Link to={'/articles'}>مقالات وأبحاث</Link>
            <span> ({data.articles.totalCount})</span>
          </p>
          <p>
            <Link to={'/astronomy_events'}>الأحداث الفلكية</Link>
            <span> ({data.astronomy_events.totalCount})</span>
          </p>
          <p>
            <Link to={'/occasions'}>مناسبات فلكية</Link>
            <span> ({data.occasions.totalCount})</span>
          </p>
        </section>
      </Container>
    </Layout>
  )
}

export default ArchivePage

export const Head = ({ location }) => <SEO pathname={location.pathname}></SEO>